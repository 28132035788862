import { gql } from '@apollo/client';

export const getCategoriesInfo = gql`
    query getCategories($ids: String) {
        categories(filters: { ids: { eq: $ids } }, pageSize: 20, currentPage: 1) {
            items {
                id
                name
                url_path
                url_key
                url_suffix
            }
        }
    }
`;

export const getMpDailyDeals = gql`
    query MpDailyDeals($dateFrom: String, $dateTo: String, $pageSize: Int, $currentPage: Int) {
        MpDailyDeals(filter: {date_from: {from: $dateFrom}, date_to: {to: $dateTo}}, pageSize: $pageSize, currentPage: $currentPage) {
            total_count
            skus
            items {
                deal_id
                product_id
                product_name
                product_sku
                status
                is_featured
                deal_price
                deal_qty
                sale_qty
                store_ids
                date_from
                date_to
                created_at
                updated_at
                discount_label
                remaining_time
                original_price
                url_small_image
                url_key
                has_promo_free_item
            }
            page_info {
                page_size
                current_page
            }
        }
    }
`;

export const getCmsPage = gql`
    query ($identifier: String!) {
        cmsPage(identifier: $identifier) {
            identifier
            content
            meta_description
            meta_keywords
            meta_title
            title
            url_key
        }
    }
`;

export const getInstagramToken = gql`
    {
        instagramToken {
            token
        }
    }
`;

export const getPageBuilderTemplate = gql`
    query ($identifier: String!) {
        getPageBuilderTemplate(id: $identifier) {
            data
        }
    }
`;

export const getInstagramFeed = gql`
    mutation getInstagramFeed($token: String!) {
        internalGetInstagramFeed(token: $token) {
            message
            data {
                id
                media_type
                media_url
                permalink
                caption
                username
            }
            err
        }
    }
`;

export const getCmsBlocks = gql`
    query ($identifiers: [String]) {
        cmsBlocks(identifiers: $identifiers) {
            items {
                identifier
                title
                content
            }
        }
    }
`;

export const getProductReviews = gql`
    query getProductReviews($sku: String, $pageSize: Int) {
        products(filter: { sku: { eq: $sku } }, pageSize: $pageSize) {
            items {
                id
                sku
                name
                url_key
                small_image {
                    url
                    label
                }
                reviews {
                    items {
                        nickname
                        summary
                        created_at
                        text
                        ratings_breakdown {
                            name
                            value
                        }
                    }
                }
            }
        }
    }
`;

export const getProductPrice = gql`
    query getProductPrice($search: String, $pageSize: Int, $filter: ProductAttributeFilterInput, $sort: ProductAttributeSortInput) {
        products(search: $search, pageSize: $pageSize, filter: $filter, sort: $sort) {
            items {
                id
                name
                sku
                url_key
                price_range {
                    maximum_price {
                        regular_price {
                            value
                        }
                        final_price {
                            value
                        }
                        discount {
                            amount_off
                            percent_off
                        }
                    }
                    minimum_price {
                        regular_price {
                            value
                        }
                        final_price {
                            value
                        }
                        discount {
                            amount_off
                            percent_off
                        }
                    }
                }
                price_tiers {
                    discount {
                        amount_off
                        percent_off
                    }
                    final_price {
                        currency
                        value
                    }
                    quantity
                }
            }
        }
    }
`;

// product_video_url removed under items
export const getProductList = gql`
    query getProductList($search: String, $pageSize: Int, $filter: ProductAttributeFilterInput, $sort: ProductAttributeSortInput) {
        products(search: $search, pageSize: $pageSize, filter: $filter, sort: $sort) {
            items {
                weltpixel_labels {
                    categoryLabel {
                        css
                        customer_group
                        image
                        page_position
                        position
                        priority
                        text
                        text_padding
                        text_bg_color
                        text_font_size
                        text_font_color          
                    }
                    productLabel {
                        css
                        customer_group
                        image
                        page_position
                        position
                        priority
                        text
                        text_padding
                        text_bg_color
                        text_font_size
                        text_font_color  
                    }
                }
                media_gallery {
                    label
                    position
                    url
                }
                seller {
                    seller_name
                }
                id
                name
                sku
                url_key
                review_count
                short_description {
                    html
                }
                price_range {
                    maximum_price {
                        regular_price {
                            value
                        }
                        final_price {
                            value
                        }
                        discount {
                            amount_off
                            percent_off
                        }
                    }
                    minimum_price {
                        regular_price {
                            value
                        }
                        final_price {
                            value
                        }
                        discount {
                            amount_off
                            percent_off
                        }
                    }
                }
                price_tiers {
                    discount {
                        amount_off
                        percent_off
                    }
                    final_price {
                        currency
                        value
                    }
                    quantity
                }
                small_image {
                    label
                    url
                }
                review {
                    rating_summary
                    reviews_count
                }
                new_from_date
                new_to_date
                stock_status
            }
        }
    }
`;

export const getPriceProductList = gql`
    query getProductList($search: String, $pageSize: Int, $filter: ProductAttributeFilterInput, $sort: ProductAttributeSortInput) {
        products(search: $search, pageSize: $pageSize, filter: $filter, sort: $sort) {
            items {
                id
                sku
                url_key
                price_range {
                    maximum_price {
                        regular_price {
                            value
                        }
                        final_price {
                            value
                        }
                        discount {
                            amount_off
                            percent_off
                        }
                    }
                    minimum_price {
                        regular_price {
                            value
                        }
                        final_price {
                            value
                        }
                        discount {
                            amount_off
                            percent_off
                        }
                    }
                }
                price_tiers {
                    discount {
                        amount_off
                        percent_off
                    }
                    final_price {
                        currency
                        value
                    }
                    quantity
                }
                stock_status
            }
        }
    }
`;

export const getImageProductList = gql`
    query getProductList($search: String, $pageSize: Int, $filter: ProductAttributeFilterInput, $sort: ProductAttributeSortInput) {
        products(search: $search, pageSize: $pageSize, filter: $filter, sort: $sort) {
            items {
                id
                sku
                url_key
                media_gallery {
                    label
                    position
                    url
                }
            }
        }
    }
`;

export const getCategories = gql`
    query getCategories($ids: [String]) {
        categoryList(filters: { ids: { in: $ids } }) {
            name
            product_count
            url_path
            children {
                name
                product_count
                url_path
                children {
                    name
                    product_count
                    url_path
                    children {
                        name
                        product_count
                        url_path
                        children {
                            name
                            product_count
                            url_path
                        }
                    }
                }
            }
        }
    }
`;

export default { getCmsPage };
