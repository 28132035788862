/* eslint-disable indent */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Header from '@core_modules/theme/components/header/components/HeaderA/adaptive/desktop';
import cx from 'classnames';

const HeaderV1 = (props) => {
    const {
        //
        storeConfig,
        handleSearch,
        setValue,
        data,
        loading,
        t,
        isLogin,
        customer,
        handleLogout,
        deviceWidth,
        appName,
        installMessage,
        ...other
    } = props;

    return (
        <>
            <div id="header-inner" className={cx('top-0', 'bg-neutral-white !fixed z-[999] w-full')}>
                <div className={cx('header-wrapper-main')}>
                    <div className="header-main">
                        <Header
                            t={t}
                            storeConfig={storeConfig}
                            isLogin={isLogin}
                            setValue={setValue}
                            handleSearch={handleSearch}
                            dataMenu={data}
                            loadingMenu={loading}
                            handleLogout={handleLogout}
                            customer={customer.customer}
                            deviceWidth={deviceWidth}
                            {...other}
                        />
                    </div>
                </div>
                <style jsx>
                    {`
                        .header-wrapper-main {
                            background-color: ${storeConfig && storeConfig.pwa && storeConfig.pwa.background_color};
                        }
                        .menu-category {
                            width: fit-content;
                            display: block;
                        }
                    `}
                </style>
                <style global jsx>
                    {`
                        .hidden-submenu {
                            display: none !important;
                            transition: display 1s ease;
                        }
                        .header-small {
                            top: -45px !important;
                        }
                        @media (min-width: 1250px) {
                            .header-small {
                                height: 75px !important;
                            }
                            .hidden-submenu {
                                display: none !important;
                                transition: display 1s ease;
                            }
                        }
                    `}
                </style>
            </div>
        </>
    );
};

export default HeaderV1;
