/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
import generateCustomCssAnimation from '@core_modules/cms/helpers/magezonCustomCssAnimationGenerator';
import magezonDesignOptionsCss from '@core_modules/cms/helpers/magezonDesignOptionsCss';
import dynamic from 'next/dynamic';
import React from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

const MagezonSlider = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonSlider'));
const MagezonCaraousel = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonCaraousel'), { ssr: false });
const MagezonColumn = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonColumn'));
const MagezonRow = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonRow'));
const MagezonHeading = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonHeading'));
const MagezonSingleImage = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonSingleImage'));
const MagezonText = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonText'));
const MagezonButton = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonButton'));
const MagezonRawHtml = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonRawHtml'), { ssr: false });
const MagezonWidget = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonWidget'), { ssr: false });
const MagezonIcon = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonIcon'), { ssr: false });
const MagezonSeparator = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonSeparator'), { ssr: false });
const MagezonEmpty = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonEmpty'), { ssr: false });
const MagezonToggle = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonToggle'), { ssr: false });
const MagezonNewsletter = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonNewsletter'), { ssr: true });
const MagezonContactForm = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonContactForm'), { ssr: false });
const MagezonSearchForm = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonSearchForm'), { ssr: false });
const MagezonStaticBlock = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonStaticBlock'));
const MagezonPagebuilderTemplate = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonPageBuilderTemplate'), {
    ssr: false,
});
const MagezonVideoPlayer = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonVideoPlayer'), { ssr: false });
const MagezonImageGallery = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonImageGallery'), { ssr: false });
const MagezonRecentReviews = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonRecentReviews'), { ssr: false });
const MagezonGoogleMaps = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonGoogleMaps'), { ssr: false });
const MagezonAccordion = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonAccordion'), { ssr: false });
const MagezonSection = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonSection'), { ssr: false });
const MagezonProduct = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonProduct'));

const MagezonInstagram = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonInstagramFeed'), { ssr: false });
const MagezonParallax = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonParallax'), { ssr: false });
const MagezonTabs = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonTabs'), { ssr: false });

const MagezonIconList = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonIconList'), { ssr: false });
const FlashSaleBlocks = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/FlashSaleBlocks'), { ssr: false });
const MagezonSingleImageFlashSale = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonSingleImageFlashSale'));

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(isBetween);

const MagezonElement = (props) => {
    const {
        align,
        type,
        content,
        animation_in,
        animation_duration,
        animation_delay,
        animation_infinite,
        parallax_type,
        parallax_speed,
        mouse_parallax,
        mouse_parallax_size,
        mouse_parallax_speed,
        background_image,
        full_height,
        xs_hide,
        sm_hide,
        md_hide,
        lg_hide,
        xl_hide,
        hidden_default,
        disable_element,
        el_class,
        el_id,
        el_inner_class,
        storeConfig,
        id,
        ...other
    } = props;
    const { base_media_url } = storeConfig;
    let childrenContent;
    let classes = 'mgz-element inline-block ';
    let customId = '';
    let innerClasses = 'mgz-element-inner ';
    const { className, styles } = generateCustomCssAnimation(animation_duration, animation_delay, animation_infinite);
    const { className: designOptionClassName, styles: designOptionStyles } = magezonDesignOptionsCss(id, { ...other, type });

    // console.log('props', props);

    const enumCustomAnimation = {
        topToBottom: 'mgz_top-to-bottom',
        bottomToTop: 'mgz_bottom-to-top',
        leftToRight: 'mgz_left-to-right',
        rightToLeft: 'mgz_right-to-left',
        appear: 'mgz_appear',
        backSlideIn: 'owl-backSlide-in',
        fadeUpIn: 'owl-fadeUp-in',
        goDownIn: 'owl-goDown-in',
    };

    if (el_class) {
        classes += `${el_class}`;
    }

    if (el_id) {
        customId += `${el_id}`;
    }

    if (el_inner_class) {
        innerClasses += `${el_inner_class}`;
    }

    if (full_height) {
        classes += 'full_height ';
    }

    if (disable_element) return null;

    if (xs_hide) classes += ' max-sm:hidden ';
    if (sm_hide) classes += ' max-md:hidden ';
    if (md_hide) classes += ' max-lg:hidden ';
    if (lg_hide) classes += ' max-xl:hidden ';
    if (xl_hide) classes += ' xl:hidden ';

    if (hidden_default) classes += 'hidden-default ';

    if (animation_in) {
        if (!Object.values(enumCustomAnimation).includes(animation_in)) {
            // base CSS animation using animate.css class and utility class
            classes += `animate__animated animate__${animation_in}`;
            if (animation_delay) {
                classes += ` animate__delay-${animation_delay}s`;
            }
            if (animation_infinite) {
                classes += ' animate__infinite';
            }
            if (animation_duration) {
                classes += ' animation_duration';
            }
        } else {
            // custom CSS animation
            classes += `${animation_in} ${className}`;
            if (animation_duration || animation_delay || animation_infinite) {
                classes += ' custom_animation';
            }
        }
    }

    const [expiredTime, setExpiredTime] = React.useState(false);
    const [timerRange, setTimerRange] = React.useState('');

    dayjs.tz.setDefault(storeConfig.timezone);
    const saleEnabled = storeConfig.mp_dailydeal_custom_enabled || false;
    const mainTime = storeConfig.mp_dailydeal_custom_timesale || '';
    const timePeriod = storeConfig.mp_dailydeal_custom_timesale_period || '';

    React.useEffect(() => {
        if (type && type === 'raw_js' && content && content !== '' && content.includes('<script>')) {
            if (typeof window !== 'undefined') {
                let code = content.replace('<script>', '');
                code = code.replace('</script>', '');
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.innerHTML = code;
                document.body.appendChild(script);
            }
        }
    }, [props]);

    React.useEffect(() => {
        const currentDate = dayjs.tz();
        const dateNow = dayjs.tz().startOf('date');
        const halfDay = dateNow.add(12, 'hours');
        const fullDay = dateNow.add(1, 'days');

        let timeBegin = '';
        let timeEnd = '';
        const timeStart = mainTime;

        if (mainTime.length) {
            const beginArr = [];
            const endArr = [];

            mainTime.forEach((time) => {
                const splitTime = time.split(':');
                const date = dateNow.add(dayjs.duration().add({ hours: splitTime[0], minutes: splitTime[1] }).add(timePeriod, 'hours'));
                const t = date.format('HH:mm');
                beginArr.push(t);
                endArr.push(t);
            });

            beginArr.unshift(beginArr.pop());
            timeBegin = beginArr;
            timeEnd = endArr;
        }

        const groupingTime = (begin, start, end) => {
            const gt = [];
            for (let i = 0; i < timeStart.length; i += 1) {
                gt.push({
                    begin: begin[i],
                    start: start[i],
                    end: end[i],
                });
            }
            return gt;
        };

        groupingTime(timeBegin, timeStart, timeEnd).forEach((time) => {
            const splitTimeBegin = timeBegin.length > 1 ? time.begin.split(':') : time.start.split(':');
            const splitTimeStart = time.start.split(':');
            const splitTimeEnd = time.end.split(':');

            const dateBegin = dateNow.add(dayjs.duration().add({ hours: splitTimeBegin[0], minutes: splitTimeBegin[1] }));
            const dateStart = dateNow.add(dayjs.duration().add({ hours: splitTimeStart[0], minutes: splitTimeStart[1] }));
            const dateEnd = dateNow.add(dayjs.duration().add({ hours: splitTimeEnd[0], minutes: splitTimeEnd[1] }));

            let tBegin = '';
            let tStart = '';
            let tEnd = '';

            if (splitTimeBegin > splitTimeStart) {
                if (currentDate.isBetween(dateNow, halfDay, null, '[)')) {
                    tBegin = dateBegin.subtract(1, 'days');
                    tStart = dateStart;
                    tEnd = dateEnd;
                }
                if (currentDate.isBetween(halfDay, fullDay, null, '[)')) {
                    tBegin = dateBegin;
                    tStart = dateStart.add(1, 'days');
                    tEnd = dateEnd.add(1, 'days');
                }
            } else if (splitTimeStart > splitTimeEnd) {
                if (currentDate.isBetween(dateNow, halfDay, null, '[)')) {
                    tBegin = dateBegin.subtract(1, 'days');
                    tStart = dateStart.subtract(1, 'days');
                    tEnd = dateEnd;
                }
                if (currentDate.isBetween(halfDay, fullDay, null, '[)')) {
                    tBegin = dateBegin;
                    tStart = dateStart;
                    tEnd = dateEnd.add(1, 'days');
                }
            } else {
                tBegin = dateBegin;
                tStart = dateStart;
                tEnd = dateEnd;
            }

            if (currentDate.isBetween(tBegin, tEnd, null, '[)')) {
                if (currentDate.isBetween(tBegin, tStart, null, '[)')) {
                    const timer = {
                        begin: dayjs.tz(tBegin),
                        start: dayjs.tz(tStart),
                        end: dayjs.tz(tEnd),
                        timer: dayjs.tz(tStart),
                        sale: false,
                    };
                    setTimerRange(timer);
                }
                if (currentDate.isBetween(tStart, tEnd, null, '[)')) {
                    const timer = {
                        begin: dayjs.tz(tBegin),
                        start: dayjs.tz(tStart),
                        end: dayjs.tz(tEnd),
                        timer: dayjs.tz(tEnd),
                        sale: true,
                    };
                    setTimerRange(timer);
                }
            }
        });
    }, [expiredTime]);

    if (expiredTime && saleEnabled && mainTime.length) {
        window.location.reload();
    }

    if (type) {
        switch (type) {
        case 'row':
            childrenContent = <MagezonRow {...props} />;
            break;
        case 'column':
            childrenContent = <MagezonColumn {...props} />;
            break;
        case 'heading':
            childrenContent = <MagezonHeading {...props} />;
            break;
        case 'single_image':
            if (el_id === 'flash-sale-blocks') {
                childrenContent = (
                    <MagezonSingleImageFlashSale
                        {...props}
                        expiredTime={expiredTime}
                        setExpiredTime={setExpiredTime}
                        enabled={saleEnabled}
                        mainTime={mainTime}
                        rangeData={timerRange}
                    />
                );
            } else {
                childrenContent = <MagezonSingleImage {...props} />;
            }
            break;
        case 'text':
            childrenContent = <MagezonText {...props} />;
            break;
        case 'button':
            childrenContent = <MagezonButton {...props} />;
            break;
        case 'raw_html':
            childrenContent = <MagezonRawHtml {...props} />;
            break;
        case 'magento_widget':
            childrenContent = <MagezonWidget {...props} />;
            break;
        case 'instagram':
            childrenContent = <MagezonInstagram {...props} />;
            break;
        case 'icon':
            childrenContent = <MagezonIcon {...props} />;
            break;
        case 'separator':
            childrenContent = <MagezonSeparator {...props} />;
            break;
        case 'empty_space':
            childrenContent = <MagezonEmpty {...props} />;
            break;
        case 'toggle':
            childrenContent = <MagezonToggle {...props} />;
            break;
        case 'newsletter_form':
            childrenContent = <MagezonNewsletter {...props} />;
            break;
        case 'contact_form':
            childrenContent = <MagezonContactForm {...props} />;
            break;
        case 'static_block':
            childrenContent = <MagezonStaticBlock {...props} />;
            break;
        case 'slider':
            childrenContent = <MagezonSlider {...props} />;
            break;
        case 'image_carousel':
            childrenContent = <MagezonCaraousel {...props} />;
            break;
        case 'search_form':
            childrenContent = <MagezonSearchForm {...props} />;
            break;
        case 'pagebuilder_template':
            childrenContent = <MagezonPagebuilderTemplate {...props} />;
            break;
        case 'video':
            childrenContent = <MagezonVideoPlayer {...props} />;
            break;
        case 'image_gallery':
            childrenContent = <MagezonImageGallery {...props} />;
            break;
        case 'recent_reviews':
            childrenContent = <MagezonRecentReviews {...props} />;
            break;
        case 'single_product':
            childrenContent = <MagezonProduct {...props} />;
            break;
        case 'product_list':
            childrenContent = <MagezonProduct {...props} />;
            break;
        case 'product_grid':
            childrenContent = <MagezonProduct {...props} />;
            break;
        case 'product_slider':
            childrenContent = <MagezonProduct {...props} />;
            break;
        case 'gmaps':
            childrenContent = <MagezonGoogleMaps {...props} />;
            break;
        case 'section':
            childrenContent = <MagezonSection {...props} />;
            break;
        case 'accordion':
            childrenContent = <MagezonAccordion {...props} />;
            break;
        case 'tabs':
            childrenContent = <MagezonTabs {...props} />;
            break;
        case 'icon_list':
            childrenContent = <MagezonIconList {...props} />;
            break;
        case 'generate_block':
            childrenContent = (
                <FlashSaleBlocks {...props} expiredTime={expiredTime} enabled={saleEnabled} mainTime={mainTime} rangeData={timerRange} />
            );
            break;
        default:
            childrenContent = null;
        }
    }

    return (
        <>
            <div className={classes} id={customId || null}>
                <div className={cx(innerClasses, id, designOptionClassName)}>
                    {background_image && (
                        <div className="parallax-wrapper mouse-parallax">
                            <MagezonParallax
                                src={`${base_media_url}${background_image}`}
                                speed={parallax_speed}
                                type={parallax_type}
                                mouseParallax={mouse_parallax}
                                mouseSize={mouse_parallax_size}
                            />
                        </div>
                    )}
                    {childrenContent}
                </div>
            </div>
            {/* prettier-ignore */}
            <style jsx>
                {`
                    .mgz-element {
                        ${align ? `text-align: ${align};` : ''}
                        position: relative;
                        width: 100%;
                        box-sizing: border-box;
                    }
                    .mgz-element#category .category-icon {
                        padding: 10px 20px;
                    }
                    .mgz-element#blog > {
                        padding: 0 50px;
                    }
                    @media screen and (max-width: 360px) {
                        .full_height {
                            min-height: ${Math.round(storeConfig.pwa.magezon_slider_mobile_width * 0.8)}px;
                        }
                    }
                    @media screen and (min-width: 361px) and (max-width: 383px) {
                        .full_height {
                            min-height: ${Math.round(storeConfig.pwa.magezon_slider_mobile_width * 0.9)}px;
                        }
                    }
                    @media screen and (min-width: 384px) and (max-width: 479px) {
                        .full_height {
                            min-height: ${Math.round(storeConfig.pwa.magezon_slider_mobile_width * 0.95)}px;
                        }
                    }
                    @media screen and (min-width: 480px) and (max-width: 767px) {
                        .full_height {
                            min-height: ${Math.round(storeConfig.pwa.magezon_slider_mobile_width * 1.25)}px;
                        }
                    }
                    @media screen and (min-width: 768px) and (max-width: 800px) {
                        .full_height {
                            min-height: ${storeConfig.pwa.magezon_slider_mobile_height}px;
                        }
                    }
                    @media screen and (min-width: 801px) {
                        .full_height {
                            min-height: 433px;
                        }
                    }
                    .hidden-default {
                        display: none;
                    }
                `}
            </style>
            <style jsx global>
                {`
                    .mgz-column > * {
                        padding: 0px;
                    }
                    .mgz-carousel-item-container img {
                        object-fit: contain;
                    }
                    .mgz-element#block-title .mgz-column {
                        flex-basis: unset;
                        width: 100%;
                        flex: unset;
                    }
                    .mgz-element#block-title .mgz-column:last-child {
                        position: absolute;
                        flex-basis: unset;
                        width: 100%;
                        max-width: 100%;
                    }
                    .mgz-element#block-title .mgz-element-row-max-width {
                        position: relative;
                        max-width: 100%;
                    }
                    .mgz-element#block-title .view-more {
                        font-size: 20px;
                        font-weight: 700;
                        margin: 0;
                        margin-bottom: 1.7px;
                    }.mgz-element#block-title .view-more .mgz-icon-list-item i {
                        rotate: 45deg;
                        font-size: 18px;
                    }
                    .mgz-element#block-title .view-more .mgz-icon-list-item span {
                        order: -1;
                        margin-right: 5px;
                    }
                    .mgz-element#block-title .view-more .mgz-icon-list-item,
                    .mgz-element#block-title .view-more .mgz-icon-list-item a {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    }
                    .block-content-wrapper#category {
                        margin: 15px 0 30px;
                    }
                    .slider-content-wrapper#flash-sale {
                        margin: 15px 0 30px;
                    }
                    .block-content-wrapper#new-arrival {
                        margin: 15px 0 45px;
                    }
                    .block-content-wrapper#event-workshop {
                        margin: 20px 0 30px;
                    }
                    .block-content-wrapper#featured-brands {
                        margin: 20px 0 30px;
                    }
                    .block-content-wrapper#latest-video {
                        margin: 20px 0 30px;
                    }
                    .block-content-wrapper#blog {
                        margin: 20px 0 30px;
                    }
                    .block-content-wrapper#benefit {
                        margin: 0 0 30px 0;
                    }
                    .mgz-element#event-workshop .magezon-slide-image {
                        height: 25vw;
                        max-height: 308px;
                    }
                    .mgz-element#event-workshop .slick-list {
                        border-radius: 10px;
                    }
                    .mgz-element#event-workshop .magezon-slider > div {
                        height: auto;
                    }
                    .mgz-element#event-workshop .magezon-slider-button-nav {
                        height: 40px;
                        border-radius: 50%;
                        margin: 0;
                    }
                    .mgz-element#event-workshop .magezon-slider-button-nav svg {
                        font-size: 32px;
                    }
                    .mgz-element#event-workshop .magezon-slider-button-nav:first-child {
                        margin-left: -15px;
                    }
                    .mgz-element#event-workshop .magezon-slider-button-nav:last-child {
                        margin-right: -15px;
                    }
                    .mgz-element#main-banner .magezon-slide-captions *{
                        height: auto;
                    }
                    .mgz-element#event-workshop .slick-slide {
                        position: relative;
                    }
                    .mgz-element#main-banner .slick-slide{
                        position: relative;
                    }
                    .mgz-element#latest-video .mgz-column {
                        flex: unset;
                    }
                    .mgz-element#latest-video .mgz-element-row-max-width {
                        column-gap: 20px;
                        flex-wrap: nowrap;
                    }
                    .mgz-element#latest-video .mgz-video-content iframe {
                        border-radius: 10px;
                        height: 100%;
                    }
                    @media screen and (max-width: 576px) {
                        .mgz-element#latest-video .mgz-column {
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                        .mgz-element#latest-video .mgz-element-row-max-width {
                            flex-wrap: wrap;
                            column-gap: 0px;
                            row-gap: 20px;
                        }
                        .mgz-element#latest-video > div.mgz-element-inner > div.row > div:nth-child(n+3) {
                            display: none;
                        }
                    }

                    .mgz-element#blog .mgz-element-row-max-width {
                        column-gap: 20px;
                        flex-wrap: nowrap;
                    }
                    .mgz-element#blog .mgz-element-row-max-width .mgz-column {
                        flex: unset;
                    }
                    @media screen and (max-width: 576px) {
                        .mgz-element#blog .mgz-element-row-max-width {
                            flex-wrap: wrap;
                            column-gap: 0px;
                            row-gap: 20px;
                        }
                        .mgz-element#blog .mgz-element-row-max-width .mgz-column {
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                        .mgz-element#blog > div.mgz-element-inner > div.row > div:nth-child(n+3) {
                            display: none;
                        }
                    }

                    .mgz-element#benefit img {
                        padding: 0px;
                    }
                    .mgz-element#benefit .mgz-carousel-content-wrapper {
                        position: relative;
                    }
                    .mgz-element#benefit .mgz-carousel-arrow {
                        top: 50%;
                        transform: translateY(-50%);
                        background: #eee;
                        border-radius: 50%;
                    }
                    .mgz-element#benefit .mgz-carousel-arrow svg {
                        width: 1.5em;
                        height: 1.5em;
                    }
                    .mgz-element#benefit .mgz-carousel-arrow-left svg {
                        margin-left: 5px;
                    }
                    #featured-brands .mgz-column .brand-logo {
                        padding: 10px;
                        cursor: pointer;
                    }
                    #featured-brands .mgz-column .brand-logo:hover {
                        padding: 9px;
                    }
                    #featured-brands .mgz-column .brand-logo > div:hover {
                        border: 1px solid #b9b9b9;
                        border-radius: 10px;
                        overflow: hidden;
                    }




                    @media screen and (min-width: 768px) {
                        .mgz-element:not(.full_height) > .mgz-element-inner > .mgz-row > .mgz-column > .flash-sale-block > .mgz-element-inner > div > .mgz-element > .mgz-element-inner > .row {
                            padding: 0px;
                        }
                        #featured-brands > .mgz-column.flex.flex-col {
                            flex-basis: calc(100% / 4);
                            max-width: calc(100% / 4);
                        }
                        .flashsale-wrapper > div > div > div:nth-of-type(2) {
                        padding-left: 20px;
                    }
                    }

                    @media screen and (max-width: 768px) {
                        .flashsale-wrapper > div > div > div:nth-of-type(2) {
                            padding-top: 20px;
                        }
                        .mgz-element.full_height > .mgz-element-inner > .mgz-row > .mgz-column > .mgz-element {
                            margin-top: -15px;
                            position: absolute;
                        }
                        .mgz-element > .mgz-element-inner > div > p {
                            margin: 0px !important;
                            padding: 0px !important;
                        }
                        .mgz-element .magezon-slide-captions .magezon-slide-heading *{
                            font-size: 16px !important;
                        }
                        .mgz-element .magezon-slide-captions [class*="magezon-slide-caption"] *{
                            font-size: 12px !important;
                        }
                        .mgz-element#category {
                            padding: 0 15px !important;
                        }
                        .mgz-element#category .category-icon {
                            padding: 0px !important;
                        }
                        #featured-brands.mgz-column {
                            flex-basis: calc(100% / 2);
                            max-width: calc(100% / 2);
                        }

                        #block-title.block-content-wrapper {
                            padding: 0px;
                        }

                        .block-content-wrapper#category {
                            margin: 15px 0 0;
                        }
                        .slider-content-wrapper#flash-sale {
                            margin: 15px 0 0;
                        }
                        .block-content-wrapper#event-workshop {
                            margin: 20px 0 30px;
                        }
                        .block-content-wrapper#featured-brands {
                            margin: 20px 0 30px;
                        }
                        .block-content-wrapper#latest-video {
                            margin: 20px 0 30px;
                        }
                        .block-content-wrapper#blog {
                            margin: 20px 0 30px;
                        }
                        .block-content-wrapper#benefit {
                            margin: 0 0 30px 0;
                        }
                    }
                    @media screen and (max-width: 1024px) {
                        main #block-title,
                        main #block-title ~ * {
                            padding-left: 15px;
                            padding-right: 15px;
                        }
                        #featured-brands .brand-logo {
                            padding: 5px;
                        }
                        #featured-brands .brand-logo:hover {
                            padding: 4px;
                        }
                        .mgz-element#block-title .mgz-column:last-child {
                            right: -5px;
                        }
                    }

                    @media screen and (min-width: 1024px) {
                        #featured-brands .mgz-column {
                            flex-basis: calc(100% / 8) !important;
                            max-width: calc(100% / 8) !important;
                        }
                        .mgz-element#block-title .mgz-column:last-child {
                            right: -25px;
                        }
                    }

                    @media screen and (min-width: 1024px) and (max-width: 1279px) {
                        .mgz-element#event-workshop .magezon-slide-image {
                            height: 22.2vw;
                        }
                    }

                    @media screen and (min-width: 1200px) {
                        .mgz-element.full_height > .mgz-element-inner > .row > .mgz-column > .mgz-element {
                            padding-top: 0px !important;
                            padding-left: 0px !important;
                            padding-right: 0px !important;
                            padding-bottom: 0px !important
                        }
                    }

                    .animation_duration {
                        --animate-duration: ${animation_duration || 0.5}s;
                    }
                    .parallax-wrapper {
                        border-radius: inherit;
                        position: absolute;
                        top: 0;AAA
                        bottom: 0;
                        right: 0;
                        left: 0;
                        overflow: hidden;
                    }
                    .parallax-wrapper * {
                        position: absolute;
                    }
                    .parallax-wrapper.mouse-parallax {
                        transform: translateX(0);
                    }
                    .jarallax {
                        inset: -${mouse_parallax_size}px;
                        transition: transform ${mouse_parallax_speed}ms cubic-bezier(0.22, 0.63, 0.6, 0.88) 0s;

                        background-image: none;
                        background-size: auto;
                        background-position: center top;
                        background-repeat: no-repeat;
                    }
                    .jarallax * {
                        background-size: inherit !important;
                        background-position: inherit !important;
                        background-repeat: inherit !important;
                    }
                `}
            </style>
            {styles}
            {designOptionStyles}
        </>
    );
};

export default MagezonElement;
